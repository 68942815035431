import {TimeWindow, TTimeWindowParams} from "./TimeWindow";
import {TimeElapsedMaxPriceType, TRuleContext, TTimeElapsedMaxRule} from "../context";
import {WindowTarget} from "./WindowTarget";
import dayjs from "dayjs";
import {TimeWindowStateProvider} from "./TimeWindowStateProvider";


export type TimeElapsedWindowParams = TTimeWindowParams & {
    readonly rule: TTimeElapsedMaxRule
}

export class TimeElapsedWindow extends TimeWindow {

    readonly rule: TTimeElapsedMaxRule

    constructor(params: TimeElapsedWindowParams) {
        super(params);
        this.rule = params.rule
    }

    static createGenerator(params: {
        startDate: Date,
        ruleContext: TRuleContext,
    }): () => TimeElapsedWindow | undefined {
        const g = TimeElapsedWindow.iterateWindow(params)
        return () => {
            const i = g.next()
            if (i.done !== false) {
                return undefined
            }
            return i.value
        }
    }


    static findOnetimeRules(ruleContext: TRuleContext, ts: number): TTimeElapsedMaxRule[] {
        // タイムスロットを特定
        const {rules} = ruleContext
        let t = rules
            .find(i => new WindowTarget({
                    targetDay: i.targetDays
                }).match(ts).matched
            )
        if (!t) {
            t = rules.find(i => true)
        }
        if (!t) {
            return []
        }
        // Onceルールをeveryが短いものから全て出力
        return t.timeElapsedMaxRules
            // Onceのみ抽出
            .filter(i => i.type === TimeElapsedMaxPriceType.Once)
            // 有効なルールのみ処理
            .filter(i => (i.price !== undefined) && (i.every !== undefined))
            // 適用期間が短いものから処理
            .sort((a, b) => {
                if (a.every === undefined || b.every === undefined) {
                    throw new Error("Invalid Rule")
                }
                return a.every - b.every
            })
    }

    static findRepeatRules(ruleContext: TRuleContext, ts: number): TTimeElapsedMaxRule | undefined {
        // タイムスロットを特定
        const {rules} = ruleContext
        let t = rules
            .find(i => new WindowTarget({
                    targetDay: i.targetDays
                }).match(ts).matched
            )
        if (!t) {
            t = rules.find(i => true)
        }
        if (!t) {
            return undefined
        }
        // Repeatルールの最初の一つを出力
        return t.timeElapsedMaxRules
            // Onceのみ抽出
            .filter(i => i.type === TimeElapsedMaxPriceType.Repeat)
            // 有効なルールのみ処理
            .filter(i => (i.price !== undefined) && (i.every !== undefined))[0]
    }

    static* iterateWindow(params: {
        startDate: Date,
        ruleContext: TRuleContext,
    }): Generator<TimeElapsedWindow> {
        const {startDate, ruleContext} = params
        const initial = startDate.getTime()
        let since = initial
        const s = new TimeWindowStateProvider(ruleContext)

        for (const rule of this.findOnetimeRules(ruleContext, since)) {
            if (rule.every === undefined) {
                throw new Error(`rule.every cannot be undefined`)
            }
            const until = initial + rule.every
            const w = new TimeElapsedWindow({
                timestamp: {
                    initial, since, until,
                },
                rule,
                stateCalc: s.globalState(),
                stateRule: s.ruleState(rule),
                stateWindow: s.createWindowState(),
            })
            w.incrementSeq()
            yield w
            since = until
        }
        // 継続適用のルールを生成
        const hasRepeatRule = ruleContext.rules.some(r => {
            return r.timeElapsedMaxRules.some(r2 => {
                return r2.type === TimeElapsedMaxPriceType.Repeat
            })
        })
        if (hasRepeatRule) {
            while (true) {
                const rule = this.findRepeatRules(ruleContext, since)
                if (!rule) {
                    // ルールがマッチする可能性がある翌日0時まで進める
                    since = dayjs(since).tz("Asia/Tokyo").startOf("day").add(1, "day").toDate().getTime()
                    continue
                }
                const until = since + rule.every!
                const w = new TimeElapsedWindow({
                    timestamp: {
                        initial, since, until,
                    },
                    rule,
                    stateCalc: s.globalState(),
                    stateRule: s.ruleState(rule),
                    stateWindow: s.createWindowState(),
                })
                w.incrementSeq()
                yield w
                since = until
            }
        }
    }
}