import {ParkingPriceCode} from "../core/ParkingPriceCode";
import {useContext} from "react";
import {RuleContext} from "../context";

export const PageUpdater = () => {

    const {ruleContext} = useContext(RuleContext)

    // document.title = '駐車料金早見表';
    // window.history.replaceState(null, "", `/?code=${new ParkingPriceCode(ruleContext).code}`)

    return null;
};