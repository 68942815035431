import {TTimestamp, TWindowState} from "./types";

export type TTimeWindowParams = {
    readonly timestamp: TTimestamp
    readonly stateCalc: TWindowState,
    readonly stateRule: TWindowState,
    readonly stateWindow: TWindowState,
}

export class TimeWindow implements TTimeWindowParams {
    readonly timestamp: TTimestamp
    readonly stateCalc: TWindowState
    readonly stateRule: TWindowState
    readonly stateWindow: TWindowState

    protected constructor(params: TTimeWindowParams) {
        this.timestamp = params.timestamp
        this.stateCalc = params.stateCalc
        this.stateRule = params.stateRule
        this.stateWindow = params.stateWindow
    }

    isTarget(ts: number): boolean {
        return this.timestamp.since <= ts && ts < this.timestamp.until
    }

    incrementSeq() {
        for (const i of [this.stateCalc, this.stateRule, this.stateWindow]) {
            i.seq++
        }
    }

    addSum(price: number) {
        for (const i of [this.stateCalc, this.stateRule, this.stateWindow]) {
            i.sum += price
        }
    }

    useMax() {
        for (const i of [this.stateCalc, this.stateRule, this.stateWindow]) {
            i.maxCount++
        }
    }
}