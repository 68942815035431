import React, {ChangeEvent, useCallback, useContext} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {DayTarget, TDayTarget} from "../../core/WindowTarget";
import {modifyRule, modifyRuleOrder, RuleContext, TRuleContext} from "../../context";

type TOperationChoice = {
    readonly label: string
    readonly dayTarget?: readonly TDayTarget[]
}

const OperationChoice = {
    None: {
        label: '全日同一料金',
    },
    NS: {
        label: '日祝別料金',
        dayTarget: [
            DayTarget.DayOfWeekSunday,
            DayTarget.HolidayJapan,
        ]
    },
    DNS: {
        label: '土日祝別料金',
        dayTarget: [
            DayTarget.DayOfWeekSaturday,
            DayTarget.DayOfWeekSunday,
            DayTarget.HolidayJapan,
        ]
    },
} as const satisfies {
    [key: string]: TOperationChoice
}

function choiceForRule(ruleContext: TRuleContext): TOperationChoice {
    const specialDayRule = ruleContext.rules.find(i => {
        if (i.timeslot.isNight) {
            return false
        }
        return i.targetDays && i.targetDays.length > 0
    })
    if (!specialDayRule) {
        return OperationChoice.None
    }
    if (specialDayRule.targetDays!.length === 2) {
        return OperationChoice.NS
    }
    return OperationChoice.DNS
}

export const OperationMenu = () => {
    const {ruleContext, setRuleContext} = useContext(RuleContext);

    const onChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            const choice = Object.values(OperationChoice).find(i => i.label === e.target.value) as TOperationChoice | undefined
            if (!choice) {
                return
            }
            let u = ruleContext
            if (choice === OperationChoice.None) {
                u = {
                    ...u,
                    rules: u.rules.filter(i => {
                        return i.timeslot.isNight || i.targetDays === undefined
                    })
                }
            } else if (choice.dayTarget && choice.dayTarget.length > 0) {
                const mainRule = u.rules.find(i => i.timeslot.isNight !== true && i.targetDays === undefined)
                if (!mainRule) {
                    return
                }
                const specialDayRule = u.rules.find(i => i.timeslot.isNight !== true && i.targetDays !== undefined)
                if (specialDayRule) {
                    u = modifyRule(u, specialDayRule, () => {
                        return {
                            ...specialDayRule,
                            targetDays: choice.dayTarget ?? []
                        }
                    })
                } else {
                    // copy main rule
                    u = {
                        ...u,
                        rules: [
                            ...u.rules,
                            {
                                ...mainRule,
                                targetDays: choice.dayTarget ?? []
                            }
                        ]
                    }
                }
            } else if (choice === OperationChoice.NS) {

            }
            setRuleContext(modifyRuleOrder(u))
        },
        [ruleContext]
    );

    return (
        <>
            <InputGroup className="mb-3">
                <InputGroup.Text>料金体系</InputGroup.Text>
                <Form.Select size="sm"
                             value={choiceForRule(ruleContext).label}
                             onChange={onChange}
                >
                    {Object.values(OperationChoice).map((i, index) => (
                        <option key={index}>{i.label}</option>
                    ))}
                </Form.Select>
            </InputGroup>
        </>
    );
};