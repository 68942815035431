import React, {createContext, Dispatch, FC, ReactNode, SetStateAction, useState,} from "react";
import {TRule, TRuleContext, TTimeElapsedMaxRule, TTimeSlotMaxRule} from "./types";
import {defaultRuleContext} from "./utils";
import {TOperatingHour} from "../core/types";

export const RuleContext = createContext(
    {} as {
        ruleContext: TRuleContext;
        setRuleContext: Dispatch<SetStateAction<TRuleContext>>;
    }
);
export const RuleContextProvider: FC<{
    children: ReactNode;
}> = (props) => {
    const {children} = props;

    const [ruleContext, setRuleContext] = useState<TRuleContext>(defaultRuleContext());

    if (ruleContext.rules.some(i => i.timeslot.operatingHour === undefined)) {
        // 夜間料金を基準に、全てのオペレーション時間を更新
        let u = ruleContext
        const nightRule = u.rules.find(i => i.timeslot.isNight)
        if (nightRule) {
            u = modifyNightHour(u, nightRule.timeslot.operatingHour ?? {
                from: {
                    hour: 20, min: 0
                },
                to: {
                    hour: 7, min: 0
                }
            })
        } else {
            u = modifyNightHour(u)
        }
        setRuleContext(u)
    }


    return (
        <RuleContext.Provider value={{ruleContext, setRuleContext}}>
            {children}
        </RuleContext.Provider>
    );
};

export function modifyRule(ruleContext: TRuleContext, rule: TRule, updater: () => TRule): TRuleContext {
    return {
        ...ruleContext,
        rules: ruleContext.rules.map((i) => {
            if (i !== rule) {
                return i
            }
            return updater()
        })
    }
}

export function modifyRuleOrder(ruleContext: TRuleContext): TRuleContext {
    return {
        ...ruleContext,
        rules: ruleContext.rules.sort((a, b) => {
            const aKey = [
                a.timeslot.isNight ? "1" : "0",
                a.targetDays?.length ?? 0,
            ].join("-")
            const bKey = [
                b.timeslot.isNight ? "1" : "0",
                b.targetDays?.length ?? 0,
            ].join("-")
            return aKey.localeCompare(bKey)
        })
    }
}

export function modifyTimeSlotMaxRule(ruleContext: TRuleContext, rule: TTimeSlotMaxRule, updater: () => TTimeSlotMaxRule): TRuleContext {
    return {
        ...ruleContext,
        rules: ruleContext.rules.map((i) => {
            if (i.timeslot.maxRules !== rule) {
                return i
            }
            return {
                ...i,
                timeslot: {
                    ...i.timeslot,
                    maxRules: updater()
                }
            }
        })
    }
}

export function modifyTimeElapsedMaxRule(ruleContext: TRuleContext, rule: TTimeElapsedMaxRule, updater: () => TTimeElapsedMaxRule): TRuleContext {
    return {
        ...ruleContext,
        rules: ruleContext.rules.map((i) => {
            return {
                ...i,
                timeElapsedMaxRules: i.timeElapsedMaxRules.map(j => {
                    if (j !== rule) {
                        return rule
                    }
                    return updater()
                })
            }
        })
    }
}

export function modifyNightHour(ruleContext: TRuleContext, nightHour?: TOperatingHour): TRuleContext {
    if (nightHour) {
        const dayHours: TOperatingHour = {
            from: nightHour.to,
            to: nightHour.from,
        }
        for (const i of ruleContext.rules) {
            ruleContext = modifyRule(ruleContext, i, () => {
                return {
                    ...i,
                    timeslot: {
                        ...i.timeslot,
                        operatingHour: i.timeslot.isNight ? nightHour : dayHours
                    }
                }
            })
        }
    } else {
        const dayHours: TOperatingHour = {
            from: {
                hour: 0, min: 0
            },
            to: {
                hour: 24, min: 0
            }
        }
        for (const i of ruleContext.rules) {
            ruleContext = modifyRule(ruleContext, i, () => {
                return {
                    ...i,
                    timeslot: {
                        ...i.timeslot,
                        operatingHour: i.timeslot.isNight ? nightHour : dayHours
                    }
                }
            })
        }
    }
    return ruleContext
}