import React from "react";
import {TRule} from "../context";
import {Badge} from "react-bootstrap";

export const OperationBadge = (props: {
    rule: TRule
}) => {
    const {rule} = props

    if (rule.timeslot.isNight) {
        return (
            <Badge pill bg="primary">
                夜間
            </Badge>
        );
    } else if (rule.targetDays && rule.targetDays.length > 0) {
        return (
            <Badge pill bg="danger">
                {rule.targetDays.join("")}
            </Badge>
        )
    } else {
        return (
            <Badge pill bg="secondary">
                基本料金
            </Badge>
        )
    }
    return (
        <Badge pill bg="primary">
            Missing def
        </Badge>
    )
};

