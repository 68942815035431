import React, {useContext} from "react";
import {DesignContext, RuleContext, TargetDateContext} from "../../context";
import {Alert} from "react-bootstrap";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import 'dayjs/locale/ja'
import {calc} from "../Calculator";
import {MaxReachedBadgeView} from "../MaxReachedBadgeView";

dayjs.extend(timezone);
dayjs.extend(utc);

export const TimebaseResultTable = () => {
    const {ruleContext} = useContext(RuleContext);
    const {targetDateContext} = useContext(TargetDateContext);
    const {designContext} = useContext(DesignContext);
    const resultContext = calc(ruleContext, targetDateContext)

    if (!resultContext.hasResult) {
        return (
            <Alert variant="warning">
                {resultContext.reason}
            </Alert>
        )
    }
    const {fees, timeSlotWindows, overrideWindows} = resultContext

    const initial = dayjs(fees[0].timestamp.initial).tz("Asia/Tokyo")
    const from = initial.startOf("hour")
    const to = dayjs(fees[fees.length - 1].timestamp.until).tz("Asia/Tokyo").endOf("hour")
    const offset = initial.diff(from, "minutes")

    // 最小の課金単位から高さを決める
    const minEvery = Math.min(...fees.map(i => i.timestamp.until - i.timestamp.since))

    const remPerMin = 1.5 / Math.max(Math.min(minEvery / 1000 / 60, 60), 10)

    const timeMarkers: string[] = []
    for (let i = from; i.isBefore(to); i = i.add(1, "hour")) {
        if (i.hour() === 0) {
            timeMarkers.push(i.locale('ja').format("M/D(ddd)"))
        } else {
            timeMarkers.push(i.locale('ja').format("H:mm"))
        }
    }

    const elapsedTimeMakers: string[] = []
    for (let i = 0; initial.add(i, "hour").isBefore(to); i++) {
        const d = Math.floor(i / 24)
        const h = i % 24

        elapsedTimeMakers.push([
            d !== 0 ? `${d}d` : undefined,
            h !== 0 ? `${h}h` : undefined,
        ].filter(i => i).join(" "))
    }

    const sideWidth = "5rem"

    return (
        <div style={{
            position: "relative",
            overflow: "hidden",
            width: "100%",
            height: `${remPerMin * to.diff(from, "minutes")}rem`
        }}>
            {elapsedTimeMakers.map((i, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            position: "absolute",
                            left: `calc(100% - ${sideWidth})`,
                            width: sideWidth,
                            top: `${(offset + index * 60) * remPerMin}rem`,
                            height: `${60 * remPerMin}rem`,
                            borderTop: '1px dashed lightgreen',
                            textAlign: "right",
                            zIndex: 12,
                        }}>
                        <div style={{
                            position: "relative",
                            top: "-0.8rem",
                        }}>
                            {i}
                        </div>
                    </div>
                )
            })}
            {timeMarkers.map((i, index) => {
                if (index === 0) {
                    return <></>
                }
                return (
                    <div
                        key={index}
                        style={{
                            position: "absolute",
                            width: sideWidth,
                            top: `${index * 60 * remPerMin}rem`,
                            height: `${60 * remPerMin}rem`,
                            borderTop: '1px dashed lightblue',
                            zIndex: 12,
                        }}>
                        <div style={{
                            position: "relative",
                            top: "-0.8rem",
                        }}>
                            {i}
                        </div>
                    </div>
                )
            })}
            {timeSlotWindows.map((i, index) => {
                const top = dayjs(i.timestamp.since).diff(from, "minutes")
                const height = dayjs(i.timestamp.until).diff(dayjs(i.timestamp.since), "minutes")
                return (
                    <div
                        key={index}
                        style={{
                            position: "absolute",
                            left: "4rem",
                            width: "0.8rem",
                            top: `${top * remPerMin}rem`,
                            height: `${height * remPerMin}rem`,
                            zIndex: 10,
                            padding: "0.8px",
                        }}>
                        <div style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: `${designContext.colorFor({rule: i.rule})}`,
                            borderRadius: "0.8rem"
                        }}>
                        </div>
                    </div>
                )
            })}
            {overrideWindows.map((i, index) => {
                const top = dayjs(i.timestamp.since).diff(from, "minutes")
                const height = dayjs(i.timestamp.until).diff(dayjs(i.timestamp.since), "minutes")

                return (
                    <div
                        key={index}
                        style={{
                            position: "absolute",
                            left: `calc(100% - 4.8rem)`,
                            width: "0.8rem",
                            top: `${top * remPerMin}rem`,
                            height: `${height * remPerMin}rem`,
                            zIndex: 10,
                            padding: "0.8px",
                        }}>
                        <div style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: `${designContext.colorFor({timeElapsedMaxRule: i.rule})}`,
                            borderRadius: "0.8rem",
                        }}>
                        </div>
                    </div>
                )
            })}
            {fees.map((i, index) => {
                const top = dayjs(i.timestamp.since).diff(from, "minutes")
                const height = dayjs(i.timestamp.until).diff(dayjs(i.timestamp.since), "minutes")


                return (
                    <div
                        key={index}
                        style={{
                            position: "absolute",
                            left: sideWidth,
                            width: `calc(100% - 2*${sideWidth})`,
                            top: `${top * remPerMin}rem`,
                            height: `${height * remPerMin}rem`,
                            textAlign: "right",
                            verticalAlign: "middle",
                            zIndex: 11,
                            paddingBottom: "0.1rem"
                        }}>
                        <div style={{
                            border: `0.5px solid ${designContext.colorFor({fee: i})}`,
                            width: "100%",
                            height: "100%",
                        }}>
                            <div style={{
                                position: "absolute",
                                textAlign: "left"
                            }}>
                                <div style={{
                                    position: "relative",
                                    left: "0.8rem",
                                    top: "-1rem",
                                    backgroundColor: "white",
                                    padding: "0.2rem",
                                }}>
                                    {dayjs(i.timestamp.since).tz("Asia/Tokyo").format("H:mm")}
                                    〜
                                </div>
                            </div>
                            <div style={{
                                position: "absolute",
                                textAlign: "left",
                                paddingLeft: "3.2rem",
                                paddingTop: "0.3rem",
                            }}>
                                <>
                                    {("timeSlotMaxUsed" in i) && i.timeSlotMaxUsed ? (
                                        <MaxReachedBadgeView rule={i.timeSlotRule}/>
                                    ) : null}
                                    {("timeElapsedMaxUsedRule" in i) && i.timeElapsedMaxUsedRule ? (
                                        <MaxReachedBadgeView timeElapsedMaxRule={i.timeElapsedMaxUsedRule}/>
                                    ) : null}
                                </>
                            </div>
                            <div style={{
                                paddingRight: "0.5rem"
                            }}>
                                <>
                                    {
                                        ("timeSlotRule" in i) ?
                                            formatYen(i.total)
                                            :
                                            "〜" + formatYen(i.total)
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

function formatYen(n: number): string {
    return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY',
    }).format(n)
}