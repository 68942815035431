import React, {useContext} from "react";
import {Form} from "react-bootstrap";
import {RuleContext} from "../../context";
import {OperationMenu} from "./OperationMenu";
import {NightHoursSettingView} from "./NightHoursSettingView";
import {TimeSlotView} from "./TimeSlotView";


/*
ユーザが選択する最大料金種別
内部的なデータ構造と、Userに提示するリストは異なる。
 */





export const RuleDashboard = () => {
    const {ruleContext} = useContext(RuleContext);

    return (
        <Form>
            <OperationMenu/>
            <NightHoursSettingView/>
            {ruleContext.rules.map(rule =>
                <TimeSlotView rule={rule}/>
            )}
        </Form>
    );
};