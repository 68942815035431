import React, {createContext, Dispatch, FC, ReactNode, SetStateAction, useState,} from "react";
import {TRuleContext} from "./types";
import {TimeSlotWindow} from "../core/TimeSlotWindow";
import {TimeElapsedWindow} from "../core/TimeElapsedWindow";
import {TParkingFee, TParkingFeeBase} from "../core/types";

export type TResultContext = {
    hasResult: false
    reason: string,
} | {
    hasResult: true
    rule: TRuleContext,
    fees: (TParkingFeeBase | TParkingFee)[]
    timeSlotWindows: TimeSlotWindow[]
    overrideWindows: TimeElapsedWindow[]
}
export const defaultResultContext: TResultContext = {
    hasResult: false,
    reason: "初期状態"
}

export const ResultContext = createContext(
    {} as {
        resultContext: TResultContext;
        setResultContext: Dispatch<SetStateAction<TResultContext>>;
    }
);
export const ResultContextProvider: FC<{
    children: ReactNode;
}> = (props) => {
    const {children} = props;

    const [resultContext, setResultContext] = useState<TResultContext>(defaultResultContext);

    return (
        <ResultContext.Provider value={{resultContext, setResultContext}}>
            {children}
        </ResultContext.Provider>
    );
};