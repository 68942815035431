import React, {createContext, Dispatch, FC, ReactNode, SetStateAction, useState,} from "react";

export type TTargetDateContext = {
    readonly simplified: boolean,
    readonly startDate: Date,
    readonly endDate: Date,
}

export function defaultTargetDateContext(): TTargetDateContext {
    return {
        simplified: true,
        startDate: new Date(new Date().getTime()),
        endDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 5),
    }
}

export const TargetDateContext = createContext(
    {} as {
        targetDateContext: TTargetDateContext;
        setTargetDateContext: Dispatch<SetStateAction<TTargetDateContext>>;
    }
);
export const TargetDateContextProvider: FC<{
    children: ReactNode;
}> = (props) => {
    const {children} = props;

    const [targetDateContext, setTargetDateContext] = useState<TTargetDateContext>(defaultTargetDateContext());

    return (
        <TargetDateContext.Provider value={{targetDateContext, setTargetDateContext}}>
            {children}
        </TargetDateContext.Provider>
    );
};