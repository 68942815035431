import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {THourMin, TOperatingHour} from "./types";
import {DateUtils} from "../utils";

dayjs.extend(timezone);
dayjs.extend(utc);

export const DayTarget = {
    DayOfWeekSunday: "日",
    DayOfWeekMonday: "月",
    DayOfWeekTuesday: "火",
    DayOfWeekWednesday: "水",
    DayOfWeekThursday: "木",
    DayOfWeekFriday: "金",
    DayOfWeekSaturday: "土",
    HolidayJapan: "祝",
} as const;

export type TDayTarget = (typeof DayTarget)[keyof typeof DayTarget];

export const DayTargetAlias = {
    All: [
        DayTarget.DayOfWeekMonday,
        DayTarget.DayOfWeekTuesday,
        DayTarget.DayOfWeekWednesday,
        DayTarget.DayOfWeekThursday,
        DayTarget.DayOfWeekFriday,
        DayTarget.DayOfWeekSunday,
        DayTarget.DayOfWeekSaturday,
        DayTarget.HolidayJapan,
    ],
    MonToFri: [
        DayTarget.DayOfWeekMonday,
        DayTarget.DayOfWeekTuesday,
        DayTarget.DayOfWeekWednesday,
        DayTarget.DayOfWeekThursday,
        DayTarget.DayOfWeekFriday,
    ],
    SatSunHoliday: [
        DayTarget.DayOfWeekSunday,
        DayTarget.DayOfWeekSaturday,
        DayTarget.HolidayJapan,
    ],
    MonToSat: [
        DayTarget.DayOfWeekMonday,
        DayTarget.DayOfWeekTuesday,
        DayTarget.DayOfWeekWednesday,
        DayTarget.DayOfWeekThursday,
        DayTarget.DayOfWeekFriday,
        DayTarget.DayOfWeekSaturday,
    ],
    SunHoliday: [
        DayTarget.DayOfWeekSunday,
        DayTarget.HolidayJapan,
    ],
} as const satisfies { readonly [key: string]: readonly TDayTarget[] }

// export function dayTargets(params: {
//     operationDetailType: TOperationDetailType
// }): readonly TDayTarget[] {
//     switch (params.operationDetailType) {
//         case OperationDetailType.AllDaySunHoliday:
//         case OperationDetailType.DaytimeSunHoliday: {
//             return DayTargetAlias.SunHoliday
//         }
//         case OperationDetailType.AllDaySatSunHoliday:
//         case OperationDetailType.DaytimeSatSunHoliday: {
//             return DayTargetAlias.SatSunHoliday
//         }
//         case OperationDetailType.AllDayMonToFri:
//         case OperationDetailType.DaytimeMonToFri: {
//             return DayTargetAlias.MonToFri
//         }
//         case OperationDetailType.AllDayMonToSat:
//         case OperationDetailType.DaytimeMonToSat: {
//             return DayTargetAlias.MonToSat
//         }
//     }
//     return DayTargetAlias.All
// }

type THM = {
    readonly h: number, readonly m: number
}

function timeFromMidnight(hm: THourMin): number {
    return (hm.hour * 60 + hm.min) * 60 * 1000
}

function withinTimeRange(from: THourMin, target: THourMin, to: THourMin): {
    match: boolean
    toEnd: number
} {
    const fromToTarget = timeFromMidnight(target) - timeFromMidnight(from)
    const targetToTo = timeFromMidnight(to) - timeFromMidnight(target)
    return {
        match: (fromToTarget >= 0) && (targetToTo > 0),
        toEnd: targetToTo
    }
}

export type TWindowTarget = {
    readonly timezone?: string, // default JST
    readonly targetDay?: readonly TDayTarget[],
    readonly targetTime?: TOperatingHour
}

export class WindowTarget {

    constructor(readonly target?: TWindowTarget) {
    }

    match(ts: number): {
        matched: false,
    } | {
        matched: true,
        until: number, // 時間帯の最後のタイムスタンプ
    } {
        const m = this.matchTime(ts)
        if (!m.matched) {
            return {matched: false}
        }
        if (!this.matchDay(ts + m.offset)) {
            return {matched: false}
        }
        return {
            matched: true,
            until: m.until,
        }
    }

    /*
    時刻部分のマッチングを行う。
    24:00以降にマッチした場合には、日付offsetを返す (日付判定は前日の日付で行うため）
     */
    private matchTime(ts: number): {
        matched: false,
    } | {
        matched: true,
        offset: number,
        until: number, // 時間帯の最後のタイムスタンプ
    } {
        let targetTime = this.target?.targetTime ?? {
            from: {hour: 0, min: 0},
            to: {hour: 24, min: 0},
        }
        if (timeFromMidnight(targetTime.from) > timeFromMidnight(targetTime.to)) {
            targetTime = {
                from: targetTime.from,
                to: {
                    hour: targetTime.to.hour + 24,
                    min: targetTime.to.min,
                }
            }
        }

        const r1 = withinTimeRange(targetTime.from, DateUtils.HourMin(ts), targetTime.to)
        if (r1.match) {
            return {
                matched: true,
                offset: 0,
                until: r1.toEnd,
            }
        }
        const r2 = withinTimeRange(targetTime.from, DateUtils.HourMin(ts, {hour: 24}), targetTime.to)
        if (r2.match) {
            return {
                matched: true,
                offset: -1,
                until: r2.toEnd,
            }
        }
        return {matched: false}
    }

    private matchDay(ts: number): boolean {
        const targetDay = this.target?.targetDay
        if (targetDay === undefined) {
            return true
        }
        const dayTarget = [
            DayTarget.DayOfWeekSunday,
            DayTarget.DayOfWeekMonday,
            DayTarget.DayOfWeekTuesday,
            DayTarget.DayOfWeekWednesday,
            DayTarget.DayOfWeekThursday,
            DayTarget.DayOfWeekFriday,
            DayTarget.DayOfWeekSaturday,
        ][DateUtils.DayOfWeek(ts)]

        // 該当日が対象日か確認する
        // \TODO 実装
        // console.log(JSON.stringify({dayTarget, targetDay}, null, 2))

        if (targetDay.includes(DayTarget.HolidayJapan)) {
            if (DateUtils.IsHoliday(ts)) {
                return true
            }
        }

        const hit = targetDay.includes(dayTarget)
        return hit
    }


}