import {TimeWindow, TTimeWindowParams} from "./TimeWindow";
import {TRule, TRuleContext, TTimeSlotRule} from "../context";
import {WindowTarget} from "./WindowTarget";
import {TimeWindowStateProvider} from "./TimeWindowStateProvider";

export type TimeSlotWindowParams = TTimeWindowParams & {
    readonly rule: TRule
}

export class TimeSlotWindow extends TimeWindow {
    readonly rule: TRule

    constructor(params: TimeSlotWindowParams) {
        super(params)
        this.rule = params.rule
    }

    static createGenerator(params: {
        startDate: Date,
        ruleContext: TRuleContext,
    }): () => TimeSlotWindow {
        const g = TimeSlotWindow.iterateWindow(params)
        return () => {
            const i = g.next()
            if (i.done !== false) {
                throw new Error("No window")
            }
            return i.value
        }
    }

    static findTimeSlotRules(ruleContext: TRuleContext, ts: number): {
        rule: TRule,
        until: number,
    } {
        // タイムスロットを特定
        const {rules} = ruleContext
        for (const filterByDay of ["night", "dayFiltered", "any"]) {
            for (const t of rules) {
                if (filterByDay === "night" && !t.timeslot.isNight) {
                    continue
                } else if (filterByDay === "dayFiltered" && (t.targetDays === undefined || t.targetDays.length === 0)) {
                    continue
                }
                const m = new WindowTarget({
                    targetDay: t.targetDays,
                    targetTime: t.timeslot.operatingHour,
                }).match(ts)
                if (m.matched) {
                    return {
                        rule: t,
                        until: m.until,
                    }
                }
            }
        }
        throw new Error("Invalid timeslot")
    }

    static* iterateWindow(params: {
        startDate: Date,
        ruleContext: TRuleContext,
    }): Generator<TimeSlotWindow> {
        const {startDate, ruleContext} = params
        const initial = startDate.getTime()
        let since = initial
        const s = new TimeWindowStateProvider(ruleContext)

        while (true) {
            const {rule, until: u} = this.findTimeSlotRules(ruleContext, since)
            const {timeslot} = rule

            const until = since + u
            const w = new TimeSlotWindow({
                timestamp: {
                    initial, since, until,
                },
                rule,
                stateCalc: s.globalState(),
                stateRule: s.ruleState(timeslot),
                stateWindow: s.createWindowState(),
            })
            w.incrementSeq()
            yield w
            since = until
        }
    }
}