import React from "react";
import {TTimeElapsedMaxPriceType, TRule, TTimeSlotMaxPriceType, TTimeElapsedMaxRule} from "../context";
import {Badge} from "react-bootstrap";
import {NumberUtils, TimeUtils} from "../utils";

export const MaxReachedBadgeView = (props: {
    rule: TRule,
} | {
    timeElapsedMaxRule: TTimeElapsedMaxRule
}) => {
    if ("rule" in props) {
        const r = props.rule
        if (r.timeslot.isNight) {
            return (
                <Badge pill bg="primary">
                    {r.timeslot.maxRules.type + NumberUtils.FormatYen(r.timeslot.maxRules.price!)}
                </Badge>
            );
        } else if (r.targetDays && r.targetDays.length > 0) {
            return (
                <Badge pill bg="danger">
                    {r.timeslot.maxRules.type + NumberUtils.FormatYen(r.timeslot.maxRules.price!)}
                </Badge>
            )
        } else {
            return (
                <Badge pill bg="secondary">
                    {r.timeslot.maxRules.type + NumberUtils.FormatYen(r.timeslot.maxRules.price!)}
                </Badge>
            )
        }
    } else if ("timeElapsedMaxRule" in props) {
        const r = props.timeElapsedMaxRule
        return (
            <Badge pill bg="success">
                {props.timeElapsedMaxRule.type + NumberUtils.FormatYen(r.price!) + "/" + TimeUtils.MSecToHour(r.every!) + "h"}
            </Badge>
        )
    }
    throw new Error("Missing def")
};

