import {TOperatingHour} from "../core/types";
import {TDayTarget} from "../core/WindowTarget";
import {DesignContext} from "./DesignContext";

export const TimeSlotMaxPriceType = {
    None: '最大料金なし', // 最(無)
    // 時間帯ベース
    Park: "時駐",
    First: "時初",
    Each: "時各",
    Always: "時毎",
} as const

export type TTimeSlotMaxPriceType = typeof TimeSlotMaxPriceType[keyof typeof TimeSlotMaxPriceType];

export const TimeElapsedMaxPriceType = {
    Once: "経初", // 最(初)
    Repeat: "経繰", // 最(繰)
} as const

export type TTimeElapsedMaxPriceType = typeof TimeElapsedMaxPriceType[keyof typeof TimeElapsedMaxPriceType];

export type TTimeSlotMaxRule = {
    readonly type: TTimeSlotMaxPriceType,
    readonly price?: number
}

export type TTimeSlotRule = {
    readonly isNight?: boolean
    readonly operatingHour?: TOperatingHour, // 省略時は終日
    readonly price?: number,
    readonly every?: number,
    readonly maxRules: TTimeSlotMaxRule
}

export type TTimeElapsedMaxRule = {
    readonly type: TTimeElapsedMaxPriceType,
    readonly price?: number
    readonly every?: number
}

export type TRule = {
    readonly targetDays?: readonly TDayTarget[] // 省略時は任意の日
    readonly timeslot: TTimeSlotRule,
    readonly timeElapsedMaxRules: readonly TTimeElapsedMaxRule[]
}

export type TRuleContext = {
    readonly label?: string,
    readonly rules: TRule[]
}