import {modifyTimeSlotMaxRule, RuleContext, TimeSlotMaxPriceType, TTimeSlotMaxRule} from "../../context";
import React, {ChangeEvent, useCallback, useContext} from "react";
import {NumberUtils} from "../../utils";
import {Form, InputGroup} from "react-bootstrap";

export const TimeSlotMaxView = (props: {
    rule: TTimeSlotMaxRule,
}) => {
    const {rule} = props
    if (rule.type === TimeSlotMaxPriceType.None) {
        return null
    }
    const {ruleContext, setRuleContext} = useContext(RuleContext);
    const onChangePrice = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setRuleContext(modifyTimeSlotMaxRule(ruleContext, rule, () => {
            return {
                ...rule,
                price: NumberUtils.IgnoreNaN(parseInt(e.target.value))
            }
        }))
    }, [ruleContext])

    return (
        <InputGroup className="mb-3">
            <InputGroup.Text>{rule.type}</InputGroup.Text>
            <Form.Control
                inputMode="numeric"
                pattern={"\d*"}
                name="max"
                placeholder="料金"
                aria-label="料金"
                value={rule.price ?? ""}
                onChange={onChangePrice}
            />
            <InputGroup.Text>円</InputGroup.Text>
        </InputGroup>
    )
}