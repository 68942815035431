import React, {createContext, Dispatch, FC, ReactNode, SetStateAction, useState,} from "react";

export type TModalResultContext = {
    visible: boolean
}
export const ModalResultContext = createContext(
    {} as {
        modalResultContext: TModalResultContext;
        setModalResultContext: Dispatch<SetStateAction<TModalResultContext>>;
    }
);
export const ModalResultContextProvider: FC<{
    children: ReactNode;
}> = (props) => {
    const {children} = props;

    const [modalResultContext, setModalResultContext] = useState<TModalResultContext>({
        visible: false
    });

    return (
        <ModalResultContext.Provider value={{
            modalResultContext,
            setModalResultContext,
        }}>
            {children}
        </ModalResultContext.Provider>
    );
};