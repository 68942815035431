import {TWindowState} from "./types";
import {TRuleContext, TTimeElapsedMaxRule, TTimeSlotRule} from "../context";

export class TimeWindowStateProvider {

    private readonly _globalState: TWindowState
    private readonly _ruleStates: { [key: string]: TWindowState } = {}

    private static createState(): TWindowState {
        return {
            seq: 0,
            sum: 0,
            maxCount: 0,
        }
    }

    constructor(readonly ruleContext: TRuleContext) {
        this._globalState = TimeWindowStateProvider.createState()
    }

    globalState(): TWindowState {
        return this._globalState
    }

    ruleState(rule: TTimeSlotRule | TTimeElapsedMaxRule) {
        const key = this.keyForRule(rule)
        if (key in this._ruleStates) {
            return this._ruleStates[key]
        }
        return this._ruleStates[key] = TimeWindowStateProvider.createState()

    }

    private keyForRule(rule: TTimeSlotRule | TTimeElapsedMaxRule): string {
        for (const [iIndex, i] of Object.entries(this.ruleContext.rules)) {
            if (i.timeslot === rule) {
                return `${iIndex}`
            }
            for (const [jIndex, j] of Object.entries(i.timeElapsedMaxRules)) {
                if (j === rule) {
                    return `${iIndex}-${jIndex}`
                }
            }
        }
        throw new Error("Rule not in RuleContext")
    }

    createWindowState(): TWindowState {
        return TimeWindowStateProvider.createState()
    }

}