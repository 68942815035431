import {Alert} from "react-bootstrap";

export const DisclaimerView = () => {
    return (
        <Alert variant="light" style={{
            marginTop: "1rem",
            fontSize: "70%",
            padding: "0.4rem",
        }}>
            免責
            <ul>
                <li>コインパーキングには最大駐車時間が定められている場合が多く、超過時には異なる料金が請求される可能性があります。</li>
                <li>適切に料金設定を行っていても、料金計算結果が実際のコインパーキングと異なる可能性があります。</li>
                <li>本サイトの利用にして生じた一切の不利益について、作者は責任を負いません。</li>
            </ul>
        </Alert>
    )
}