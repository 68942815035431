/*
設計方針

料金体系を下記のように整理する
- 車種、駐車場所によるルール
  - ハイルーフ車、バイク
  - 平置きと立体での金額差
- 駐車した時刻を起点としたルール -> OverrideRule -> OverrideWindow
 - 駐車後一定時間無料
 - 駐車後n時間最大設定
   - 繰り返し適用の有無
- 時間帯によるルール -> BaseRule -> BaseWindow
 - 日中、夜間の金額が違うケース
 - 時間帯内最大設定
    - 繰り返し適用の有無


将来的な駐車場への拡張
- 駐車場は複数のルールを持つことで、車種による料金差や、車庫番号による料金差を吸収する


  タイムズの解説
  https://times-info.net/info/max_fee/

    日本パーキングサービス
    https://npc-npc.co.jp/user/charge/max/

    他の解説（ここかなり詳しい）
  https://withplace.co.jp/drive/parking-maximum_price
 */

import {
    TimeElapsedMaxPriceType,
    TTimeElapsedMaxPriceType,
    TimeSlotMaxPriceType,
    TRuleContext,
    TTimeSlotMaxPriceType, TTimeElapsedMaxRule
} from "../context";
import {TimeSlotWindow} from "./TimeSlotWindow";
import {TimeElapsedWindow} from "./TimeElapsedWindow";
import {TParkingFee, TParkingFeeBase} from "./types";

export type PriceWindowGeneratorParams = {
    readonly startDate: Date,
    readonly endDate: Date,
    readonly rule: TRuleContext
}

export class PriceWindowGenerator {
    constructor(readonly params: PriceWindowGeneratorParams) {
    }

    get startDate() {
        return this.params.startDate
    }

    get endDate() {
        return this.params.endDate
    }


    allTimeSlotWindows(): TimeSlotWindow[] {
        console.log("allTimeSlotWindows")
        const r: TimeSlotWindow[] = []
        for (const i of TimeSlotWindow.iterateWindow({
            ruleContext: this.params.rule,
            startDate: this.params.startDate,
        })) {
            if (i.timestamp.since > this.endDate.getTime()) {
                break
            }
            r.push(i)
        }
        return r
    }

    allTimeElapsedWindows(): TimeElapsedWindow[] {
        console.log("allTimeElapsedWindows")
        const r: TimeElapsedWindow[] = []
        for (const i of TimeElapsedWindow.iterateWindow({
            ruleContext: this.params.rule,
            startDate: this.params.startDate,
        })) {
            if (i.timestamp.since > this.params.endDate.getTime()) {
                break
            }
            r.push(i)
        }
        return r
    }

    allFees(simplified: boolean = true): (TParkingFee | TParkingFeeBase)[] {
        const fees: TParkingFeeBase[] = []
        if (simplified) {
            for (const w of this.iterateSimplifiedFee()) {
                fees.push(w)
            }
        } else {
            for (const w of this.iterateFee()) {
                fees.push(w)
            }
        }
        return fees
    }


    * iterateFee(): Generator<TParkingFee> {
        // ルールジェネレータ初期化
        const timeSlotWindowGen = TimeSlotWindow.createGenerator({
            ruleContext: this.params.rule,
            startDate: this.params.startDate
        })

        const timeElapsedWindowGen = TimeElapsedWindow.createGenerator({
            ruleContext: this.params.rule,
            startDate: this.params.startDate
        })

        //最初のルールを読み込む
        console.log(`Loading Initial timeSlotWindowGen`)
        let timeSlotWindow = timeSlotWindowGen()
        console.log(`Loading Initial timeElapsedWindowGen`)
        let timeElapsedWindow = timeElapsedWindowGen()

        let t = this.startDate.getTime()

        while (true) {
            const {timeslot} = timeSlotWindow.rule
            if (
                (timeslot.price === undefined) ||
                (timeslot.every === undefined) ||
                (timeslot.maxRules.type !== TimeSlotMaxPriceType.None && timeslot.maxRules.price === undefined)
            ) {
                throw new Error(`Invalid timeslot`)
            }
            let fee = timeslot.price ?? 0
            let timeToAdd = timeslot.every ?? 0
            let timeSlotMaxUsed: boolean | undefined = undefined
            let timeElapsedMaxUsedRule: TTimeElapsedMaxRule | undefined = undefined


            if (timeslot.price) {
                let canUseMax = true
                switch (timeslot.maxRules.type) {
                    case TimeSlotMaxPriceType.None: {
                        canUseMax = false
                        break
                    }
                    case TimeSlotMaxPriceType.Park: {
                        canUseMax = timeSlotWindow.stateCalc.seq === 1 // 全体として初回課金時のみ
                        break
                    }
                    case TimeSlotMaxPriceType.First: {
                        canUseMax = timeSlotWindow.stateCalc.maxCount === 0 // 全体として1回だけ最大料金適用
                        break
                    }
                    case TimeSlotMaxPriceType.Each: {
                        canUseMax = timeSlotWindow.stateRule.maxCount === 0 // 各ルールごとに
                        break
                    }
                    case TimeSlotMaxPriceType.Always: {
                        canUseMax = true
                        break
                    }
                }

                if (canUseMax) {
                    const useMax =
                        // 金額が安くなる
                        (timeSlotWindow.stateWindow.sum + fee > timeslot.maxRules.price!) ||
                        // 金額は同じだが、期間が長くなる
                        (
                            (timeSlotWindow.stateWindow.sum + fee == timeslot.maxRules.price!) &&
                            (t + timeToAdd < timeSlotWindow.timestamp.until)
                        )
                    if (useMax) {
                        timeSlotWindow.useMax()
                        timeSlotMaxUsed = true
                        fee = timeslot.maxRules.price! - timeSlotWindow.stateWindow.sum
                        if (t + timeslot.every < timeSlotWindow.timestamp.until) {
                            timeToAdd = timeSlotWindow.timestamp.until - t
                        }
                    }
                }
            }
            if (timeElapsedWindow?.isTarget(t) === true) {
                if (timeElapsedWindow.rule.type === TimeElapsedMaxPriceType.Once) {
                    if (timeElapsedWindow.rule.price === undefined) {
                        throw new Error("Invalid Rule")
                    }
                    const useMax =
                        // 金額が安くなる
                        (timeElapsedWindow.stateCalc.sum + fee > timeElapsedWindow.rule.price) ||
                        // 金額は同じだが、期間が長くなる
                        (
                            (timeElapsedWindow.stateCalc.sum + fee === timeElapsedWindow.rule.price) &&
                            (t + timeToAdd < timeElapsedWindow.timestamp.until)
                        )
                    if (useMax) {
                        timeElapsedWindow.useMax()
                        timeElapsedMaxUsedRule = timeElapsedWindow.rule
                        // 全体の金額に対する上限
                        fee = timeElapsedWindow.rule.price - timeElapsedWindow.stateCalc.sum
                        timeToAdd = timeElapsedWindow.timestamp.until - t
                    }
                } else if (timeElapsedWindow.rule.type === TimeElapsedMaxPriceType.Repeat) {
                    if (timeElapsedWindow.rule.price === undefined) {
                        throw new Error("Invalid Rule")
                    }
                    const useMax =
                        // 金額が安くなる
                        (timeElapsedWindow.stateWindow.sum + fee >= timeElapsedWindow.rule.price)||
                        // 金額は同じだが、期間が長くなる
                        (
                            (timeElapsedWindow.stateWindow.sum + fee == timeElapsedWindow.rule.price) &&
                            (t + timeToAdd < timeElapsedWindow.timestamp.until)
                        )
                    if (useMax) {
                        timeElapsedWindow.useMax()
                        timeElapsedMaxUsedRule = timeElapsedWindow.rule
                        // 今回のWindow金額に対する上限
                        fee = timeElapsedWindow.rule.price - timeElapsedWindow.stateWindow.sum
                        timeToAdd = timeElapsedWindow.timestamp.until - t
                    }
                }
            }
            timeSlotWindow.addSum(fee)
            if (timeElapsedWindow?.isTarget(t) === true) {
                timeElapsedWindow?.addSum(fee)
            }
            const until = Math.min(t + timeToAdd, this.endDate.getTime())
            const result: TParkingFee = {
                fee,
                total: timeSlotWindow.stateCalc.sum,
                timeSlotMaxUsed,
                timeElapsedMaxUsedRule,
                timeSlotRule: timeSlotWindow.rule,
                timeElapsedMaxRule: timeElapsedWindow?.rule,
                timestamp: {
                    initial: this.startDate.getTime(),
                    since: t,
                    until
                },
            }
            yield result
            t = until
            if (t >= this.endDate.getTime()) {
                break
            }
            // ルール更新の確認。untilは対象時間に含まれないことに注意
            while (timeSlotWindow.timestamp.until <= t) {
                timeSlotWindow = timeSlotWindowGen()
            }
            while ((timeElapsedWindow !== undefined) && (timeElapsedWindow.timestamp.until <= t)) {
                timeElapsedWindow = timeElapsedWindowGen()
            }
        }
    }

    * iterateSimplifiedFee(): Generator<TParkingFeeBase> {
        let initial = this.startDate.getTime()
        let since = initial
        const unit = 1000 * 60 * 60 // 1 hour
        const offset = unit - 1000 * 60 // 59分後、料金計算基準
        let buf: TParkingFeeBase | undefined = undefined
        for (const fee of this.iterateFee()) {
            if (fee.timestamp.until <= since + offset) {
                continue
            }
            while (since + offset < fee.timestamp.since) {
                since += unit
            }
            const simpleFee: TParkingFeeBase = {
                fee: fee.fee,
                total: fee.total,
                timestamp: {
                    initial,
                    since,
                    until: since + unit
                }
            }
            if (buf) {
                buf.timestamp.until = simpleFee.timestamp.since
                yield buf
            }
            buf = simpleFee
            since += unit
        }
        if (buf) {
            yield buf
        }
    }
}
