import {modifyTimeElapsedMaxRule, RuleContext, TTimeElapsedMaxRule} from "../../context";
import React, {ChangeEvent, useCallback, useContext} from "react";
import {NumberUtils, TimeUtils} from "../../utils";
import {Form, InputGroup} from "react-bootstrap";

export const ElapsedTimeMaxView = (props: {
    rule: TTimeElapsedMaxRule
}) => {
    const {rule} = props
    const {ruleContext, setRuleContext} = useContext(RuleContext);
    const onChangePrice = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setRuleContext(modifyTimeElapsedMaxRule(ruleContext, rule, () => {
            return {
                ...rule,
                price: NumberUtils.IgnoreNaN(parseInt(e.target.value))
            }
        }))
    }, [rule, ruleContext])
    const onChangeEvery = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setRuleContext(modifyTimeElapsedMaxRule(ruleContext, rule, () => {
            return {
                ...rule,
                every: TimeUtils.MSecFromHour(NumberUtils.IgnoreNaN(parseInt(e.target.value)))
            }
        }))
    }, [rule, ruleContext])
    return (
        <InputGroup className="mb-3">
            <InputGroup.Text>{rule.type}</InputGroup.Text>
            <Form.Control
                placeholder="時間"
                aria-label="時間"
                value={TimeUtils.MSecToHour(rule.every) ?? ""}
                onChange={onChangeEvery}
            />
            <InputGroup.Text>時間まで</InputGroup.Text>
            <Form.Control
                placeholder="料金"
                aria-label="料金"
                value={rule.price ?? ""}
                onChange={onChangePrice}
            />
            <InputGroup.Text>円</InputGroup.Text>
        </InputGroup>
    )
}
