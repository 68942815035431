import React, {ReactNode, useCallback, useContext} from "react";
import {Alert, Button, Container, Modal} from "react-bootstrap";
import {RuleDescription} from "./RuleDescription";
import {TimebaseResultTable} from "./TimebaseResultTable";
import {ModalResultContext} from "../../context/ModalResultContext";
import {DesignContext, RuleContext, TimeSlotMaxPriceType} from "../../context";

export const ModalResultView = () => {
    const {modalResultContext, setModalResultContext} = useContext(ModalResultContext);
    const {visible} = modalResultContext


    const toggle = useCallback(() => {
        setModalResultContext({
            visible: !visible
        })
    }, [modalResultContext])

    const warnings = renderModelValidationErrors()

    return (
        <>
            {warnings.view}
            <Button variant="primary"
                    disabled={warnings.warnings.length > 0}
                    onClick={toggle}
            >
                料金表示
            </Button>
            {!visible ? null : (
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    show={visible}
                    fullscreen="md-down"
                    keyboard={true}
                    onHide={toggle}
                    onEscapeKeyDown={toggle}
                >
                    <Modal.Body className="grid-example">
                        <Container>
                            <RuleDescription/>
                            <TimebaseResultTable/>
                        </Container>
                    </Modal.Body>
                </Modal>
            )}

        </>
    )
}

const renderModelValidationErrors = (): {
    warnings: string[],
    view: ReactNode | null
} => {
    const {ruleContext} = useContext(RuleContext);
    const {designContext} = useContext(DesignContext);

    const warnings: string[] = []

    for (const r of ruleContext.rules) {
        const {timeslot, timeElapsedMaxRules} = r
        const timeSlotLabel = `「${designContext.attributeForRule(r).label}」`
        if (timeslot.price === undefined) {
            warnings.push(`${timeSlotLabel}の「金額」が設定されていません。`)
        }
        if (timeslot.every === undefined) {
            warnings.push(`${timeSlotLabel}の「期間」が設定されていません。`)
        }
        if (timeslot.maxRules.type !== TimeSlotMaxPriceType.None) {
            if (timeslot.maxRules.price === undefined) {
                warnings.push(`${timeSlotLabel}の時間帯最大料金の「金額」が設定されていません。`)
            }
        }
        for (const j of timeElapsedMaxRules) {
            if (j.price === undefined) {
                warnings.push(`${timeSlotLabel}の経過時間最大料金の「金額」が設定されていません。`)
            }
            if (j.every === undefined) {
                warnings.push(`${timeSlotLabel}の経過時間最大料金の「期間」が設定されていません。`)
            }
        }
    }

    if (warnings.length === 0) {
        return {
            warnings,
            view: null
        }
    }
    return {
        warnings,
        view: (
            <Alert variant={"warning"} style={{
                paddingBottom: "0"
            }}>
                <ul>
                    {warnings.map(i => (
                        <li>{i}</li>
                    ))}
                </ul>
            </Alert>
        )
    }
}