import {
    TimeElapsedMaxPriceType,
    RuleContext,
    TTimeElapsedMaxPriceType,
    TimeSlotMaxPriceType,
    TRule,
    TTimeElapsedMaxRule,
    TTimeSlotMaxPriceType, modifyRule
} from "../../context";
import {Form, InputGroup} from "react-bootstrap";
import React, {ChangeEvent, useCallback, useContext} from "react";
import {OperationBadge} from "../OperationBadge";
import {OperatingHour} from "../OperatingHour";

type TMaxPriceChoice = {
    readonly label: string
    readonly timeSlotRule?: TTimeSlotMaxPriceType,
    readonly elapsedTimeRules?: readonly TTimeElapsedMaxPriceType[],
}

const MaxPriceChoice = {
    None: {
        label: '最大料金なし',
    },
    OptionAlways: {
        label: "時間帯：各時間帯で毎回最大",// 1個
        timeSlotRule: TimeSlotMaxPriceType.Always,
    },
    OptionEach: {
        label: "時間帯：各時間帯で１回最大",// 1個
        timeSlotRule: TimeSlotMaxPriceType.Each,
    },
    OptionFirst: {
        label: "時間帯：いずれかの時間帯で１回最大",// 1個
        timeSlotRule: TimeSlotMaxPriceType.First,
    },
    OptionPark: {
        label: "時間帯：駐車時の時間帯で１回最大",// 1個
        timeSlotRule: TimeSlotMaxPriceType.Park,
    },
    // 時間帯ベース
    OptionRepeat: {
        label: "経過時間：繰返最大", // 1個
        elapsedTimeRules: [
            TimeElapsedMaxPriceType.Repeat,
        ],
    },
    OptionOnce: {
        label: "経過時間：初回最大", // 最大3個設定可能
        elapsedTimeRules: [
            TimeElapsedMaxPriceType.Once,
        ],
    },
} as const satisfies {
    [key: string]: TMaxPriceChoice
}

function choiceForRule(timeSlotRules: TRule, timeElapsedRules?: readonly TTimeElapsedMaxRule[]): TMaxPriceChoice {
    switch (timeSlotRules.timeslot.maxRules.type) {
        case TimeSlotMaxPriceType.Park:
            return MaxPriceChoice.OptionPark
        case TimeSlotMaxPriceType.First:
            return MaxPriceChoice.OptionFirst
        case TimeSlotMaxPriceType.Each:
            return MaxPriceChoice.OptionEach
        case TimeSlotMaxPriceType.Always:
            return MaxPriceChoice.OptionAlways
    }
    if (timeElapsedRules === undefined || timeElapsedRules.length === 0) {
        return MaxPriceChoice.None
    }
    if (timeElapsedRules[0].type === TimeElapsedMaxPriceType.Repeat) {
        return MaxPriceChoice.OptionRepeat
    }
    return MaxPriceChoice.OptionOnce
}

export const TimeSlotHeaderView = (props: {
    rule: TRule
}) => {
    const {rule} = props
    const timeElapsedRules = rule.timeElapsedMaxRules
    const choice = choiceForRule(rule, timeElapsedRules)
    const {ruleContext, setRuleContext} = useContext(RuleContext);

    const onChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            const choice = Object.values<TMaxPriceChoice>(MaxPriceChoice).find(i => i.label === e.target.value)
            if (!choice) {
                return
            }
            let r = ruleContext
            r = modifyRule(r, rule, () => {
                return {
                    ...rule,
                    timeslot: {
                        ...rule.timeslot,
                        maxRules: {
                            ...rule.timeslot.maxRules,
                            type: choice.timeSlotRule ?? TimeSlotMaxPriceType.None,
                        }
                    },
                    timeElapsedMaxRules: (choice.elapsedTimeRules ?? []).map(type => ({
                        type,
                    })),
                }
            })
            setRuleContext(r)
        },
        [rule, ruleContext]
    );

    return (
        <>
            <InputGroup className="mb-3">
                <div>
                    <OperationBadge rule={rule}/>
                </div>
                <OperatingHour operatingHour={rule.timeslot.operatingHour}/>
                <Form.Select
                    size="sm"
                    value={choice.label}
                    onChange={onChange}
                    style={{
                        marginLeft: "1.5rem"
                    }}
                >
                    {Object.values<TMaxPriceChoice>(MaxPriceChoice)
                        .filter(i => i.timeSlotRule || (!rule.timeslot.isNight) || (!i.timeSlotRule && !i.elapsedTimeRules))
                        .map(i => {
                            return (
                                <option key={i.label}>{i.label}</option>
                            )
                        })}
                </Form.Select>
            </InputGroup>
        </>
    )
}