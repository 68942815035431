import {TResultContext, TRuleContext, TTargetDateContext} from "../context";
import {PriceWindowGenerator} from "../core/PriceWindowGenerator";

export const Calculator = () => {
    return null
};

export function calc(ruleContext: TRuleContext, targetDateContext: TTargetDateContext): TResultContext {
    try {
        const pg = new PriceWindowGenerator({
            startDate: targetDateContext.startDate,
            endDate: targetDateContext.endDate,
            rule: ruleContext
        })
        return {
            hasResult: true,
            rule: ruleContext,
            fees: pg.allFees(targetDateContext.simplified),
            timeSlotWindows: pg.allTimeSlotWindows(),
            overrideWindows: pg.allTimeElapsedWindows(),
        }
    } catch (e) {
        return {
            hasResult: false,
            reason: (e instanceof Object) && ("name" in e) ? e.name as string : "エラー"
        }
    }
}