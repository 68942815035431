import React, {FC, ReactNode,} from "react";
import {RuleContextProvider} from "./RuleContext";
import {TargetDateContextProvider} from "./TargetDateContext";
import {ResultContextProvider} from "./ResultContext";
import {ModalResultContextProvider} from "./ModalResultContext";
import {DesignContextProvider} from "./DesignContext";

export const ContextsProvider: FC<{
    children: ReactNode;
}> = (props) => {
    return (
        <DesignContextProvider>
            <RuleContextProvider>
                <TargetDateContextProvider>
                    <ResultContextProvider>
                        <ModalResultContextProvider>
                            {props.children}
                        </ModalResultContextProvider>
                    </ResultContextProvider>
                </TargetDateContextProvider>
            </RuleContextProvider>
        </DesignContextProvider>
    );
};