import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {THourMin} from "./core/types";
import * as Holiday from '@holiday-jp/holiday_jp';

dayjs.extend(timezone);
dayjs.extend(utc);


export const NumberUtils = {
    IgnoreNaN: (n?: number): number | undefined => (n === undefined ? undefined : (isNaN(n) ? undefined : n)),
    FormatYen: (n: number): string => {
        return new Intl.NumberFormat('ja-JP', {
            style: 'currency',
            currency: 'JPY',
        }).format(n)
    }
} as const

export const TimeUtils = {
    MSecToMin: (ms?: number): number | undefined => (ms === undefined ? undefined : ms / 1000 / 60),
    MSecFromMin: (min?: number): number | undefined => (min === undefined ? undefined : min * 1000 * 60),
    MSecToHour: (ms?: number): number | undefined => (ms === undefined ? undefined : ms / 1000 / 60 / 60),
    MSecFromHour: (hour?: number): number | undefined => (hour === undefined ? undefined : hour * 1000 * 60 * 60),
} as const

export const DateUtils = {
    DayOfWeek: (ts: number): number => {
        return dayjs(ts).tz("Asia/Tokyo").day()
    },
    IsHoliday: (ts: number): boolean => {
        return Holiday.isHoliday(new Date(ts))
    },
    HourMin: (ts: number, offset?: Partial<THourMin>): THourMin => {
        const t = dayjs(ts).tz("Asia/Tokyo")
        return {
            hour: t.hour() + (offset?.hour ?? 0),
            min: t.minute() + (offset?.min ?? 0),
        }
    },
} as const