import {DesignContext, modifyRule, RuleContext, TRule} from "../../context";
import React, {ChangeEvent, useCallback, useContext} from "react";
import {NumberUtils, TimeUtils} from "../../utils";
import {TimeSlotHeaderView} from "./TimeSlotHeaderView";
import {Form, InputGroup} from "react-bootstrap";
import {TimeSlotMaxView} from "./TimeSlotMaxView";
import {ElapsedTimeMaxView} from "./ElapsedTimeMaxView";

export const TimeSlotView = (props: {
    rule: TRule,
}) => {
    const {rule} = props
    const {ruleContext, setRuleContext} = useContext(RuleContext);
    const {designContext} = useContext(DesignContext)
    const onChangePrice = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setRuleContext(modifyRule(ruleContext, rule, () => {
            return {
                ...rule,
                timeslot: {
                    ...rule.timeslot,
                    price: NumberUtils.IgnoreNaN(parseInt(e.target.value))
                }
            }
        }))
    }, [ruleContext])
    const onChangeEvery = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        setRuleContext(modifyRule(ruleContext, rule, () => {
            return {
                ...rule,
                timeslot: {
                    ...rule.timeslot,
                    every: TimeUtils.MSecFromMin(NumberUtils.IgnoreNaN(parseInt(e.target.value)))
                }
            }
        }))
    }, [ruleContext])


    return (
        <div style={{
            border: `1px solid ${designContext.attributeForRule(rule).color}`,
            borderRadius: "0.5rem",
            marginBottom: "0.5rem",
            paddingLeft: "0.3rem",
            paddingRight: "0.3rem",
            paddingTop: "0.4rem",
        }}>
            <TimeSlotHeaderView rule={rule}/>
            <InputGroup className="mb-3">
                <Form.Select
                    name="every"
                    size="sm"
                    value={TimeUtils.MSecToMin(rule.timeslot.every) ?? ""}
                    onChange={onChangeEvery}
                >
                    <option key={10}>10</option>
                    <option key={12}>12</option>
                    <option key={15}>15</option>
                    <option key={15}>25</option>
                    <option key={30}>30</option>
                    <option key={30}>40</option>
                    <option key={60}>60</option>
                </Form.Select>
                <InputGroup.Text>分</InputGroup.Text>
                <Form.Control
                    name="price"
                    inputMode="numeric"
                    pattern={"\d*"}
                    placeholder="単位時間金額"
                    aria-label="単位時間金額"
                    value={rule.timeslot.price ?? ""}
                    onChange={onChangePrice}
                />
                <InputGroup.Text>円</InputGroup.Text>
            </InputGroup>
            <TimeSlotMaxView rule={rule.timeslot.maxRules}/>
            {rule.timeElapsedMaxRules.map(rule =>
                <ElapsedTimeMaxView rule={rule}/>
            )}
        </div>
    )
}