// import logo from './logo.svg';
import './App.css';
import {Col, Container, Nav, Navbar, Row} from 'react-bootstrap';
import React from "react";
import {OperationMenu} from "./components/controller/OperationMenu";
import {RuleDashboard} from "./components/controller/RuleDashboard";
import {VerticalSpacer} from "./components/VerticalSpacer";
import {TimebaseResultTable} from "./components/result/TimebaseResultTable";
import {RuleDescription} from "./components/result/RuleDescription";
import {ModalResultView} from "./components/result/ModalResultView";
import {Route, Routes} from "react-router-dom";
import {DisclaimerView} from "./components/DisclaimerView";

const NotFound = () => {
    return (
        <div>
            Route not found
        </div>
    )
}

function App() {
    return (
        <Routes>
            <Route path="*" element={
                <Container>
                    <Navbar expand="lg" className="bg-body-tertiary">
                        <Container>
                            <Navbar.Brand href="#home">駐車料金早見表</Navbar.Brand>
                            <Nav.Item>
                                <Nav.Link href="https://twitter.com/toowitter">by toowitter</Nav.Link>
                            </Nav.Item>
                        </Container>
                    </Navbar>
                    <Container>
                        <VerticalSpacer height={"1rem"}/>
                        <RuleDashboard/>
                        <ModalResultView/>
                    </Container>
                    <Container>
                        <DisclaimerView/>
                    </Container>
                </Container>
            }/>
        </Routes>
    );
}


export default App;
