import React, {createContext, Dispatch, FC, ReactNode, SetStateAction, useState,} from "react";
import {TParkingFee, TParkingFeeBase} from "../core/types";
import {TRule, TTimeElapsedMaxRule} from "./types";

export class Design {
    constructor(private readonly colors: {
        normal: string
        rule2: string
        timeElapsedRule: string
    } = {
        normal: "grey",
        rule2: "red",
        timeElapsedRule: "green"
    }) {
    }

    colorFor(params: {
        fee: TParkingFeeBase | TParkingFee
    } | {
        rule: TRule,
    } | {
        timeElapsedMaxRule: TTimeElapsedMaxRule
    }): string {
        if ("rule" in params) {
            const {rule} = params
            if (rule.timeslot.isNight) {
                return "blue"
            }
            if (rule.targetDays && rule.targetDays.length > 0) {
                return "red"
            }
            return "grey"
        } else if ("fee" in params) {
            const {fee} = params
            if ("timeSlotRule" in fee) {
                return this.colorFor({rule: fee.timeSlotRule})
            }
            return "grey"
        } else if ("timeElapsedMaxRule" in params) {
            return "green"
        }
        return "pink"
    }

    attributeForRule(rule: TRule): {
        label: string,
        color: string,
    } {
        if (rule.timeslot.isNight) {
            return {
                label: "夜間",
                color: "blue",
            }

        } else if (rule.targetDays && rule.targetDays.length > 0) {
            return {
                label: rule.targetDays.join(""),
                color: "red",
            }
        } else {
            return {
                label: "基本料金",
                color: "grey",
            }
        }
    }
}

export const DesignContext = createContext(
    {} as {
        designContext: Design;
        setDesignContext: Dispatch<SetStateAction<Design>>;
    }
);
export const DesignContextProvider: FC<{
    children: ReactNode;
}> = (props) => {
    const {children} = props;

    const [designContext, setDesignContext] = useState<Design>(new Design());

    return (
        <DesignContext.Provider value={{designContext, setDesignContext}}>
            {children}
        </DesignContext.Provider>
    );
};