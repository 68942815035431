import React, {ChangeEvent, useCallback, useContext} from "react";
import {modifyNightHour, modifyRule, RuleContext, TimeSlotMaxPriceType} from "../../context";
import {Form, InputGroup} from "react-bootstrap";
import {TOperatingHour} from "../../core/types";
import {TimeUtils} from "../../utils";

export const NightHoursSettingView = () => {
    const {ruleContext, setRuleContext} = useContext(RuleContext);
    const None = "夜間料金なし"
    const choices: string[] = [None]
    let value = None
    const nightRule = ruleContext.rules.find(i => i.timeslot.isNight)
    let fromList: string[] = [
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "24:00",
    ]
    let toList: string[] = [
        "6:00",
        "7:00",
        "8:00",
        "9:00",
        "10:00",
    ]
    if (nightRule) {
        const from = [
            String(nightRule.timeslot.operatingHour?.from?.hour ?? 20).padStart(1, "0"),
            String(nightRule.timeslot.operatingHour?.from?.min ?? 0).padStart(2, "0"),
        ].join(":")
        if (!fromList.includes(from)) {
            fromList.push(from)
            fromList = fromList.sort((a, b) => a.localeCompare(b))
        }
        const to = [
            String(nightRule.timeslot.operatingHour?.to?.hour ?? 5).padStart(1, "0"),
            String(nightRule.timeslot.operatingHour?.to?.min ?? 0).padStart(2, "0"),
        ].join(":")
        if (!toList.includes(to)) {
            toList.push(to)
            toList = toList.sort((a, b) => a.localeCompare(b))
        }
        value = `${from}〜${to}`
    }

    for (const from of fromList) {
        for (const to of toList) {
            choices.push(`${from}〜${to}`)
        }
    }


    const onChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            const v = e.target.value
            let u = ruleContext
            if (v === None) {
                u = {
                    ...u,
                    rules: u.rules.filter(i => i.timeslot.isNight !== true)
                }
                u = modifyNightHour(u)
            } else {
                const baseRule = ruleContext.rules.find(r =>
                    (r.timeslot.isNight !== true) &&
                    (r.targetDays === undefined)
                )
                const [from, to] = v.split("〜")
                const [fromHour, fromMin] = from.split(":")
                const [toHour, toMin] = to.split(":")
                const nightHours: TOperatingHour = {
                    from: {
                        hour: parseInt(fromHour),
                        min: parseInt(fromMin)
                    },
                    to: {
                        hour: parseInt(toHour),
                        min: parseInt(toMin)
                    }
                }
                if (!u.rules.some(i => i.timeslot.isNight)) {
                    u = {
                        ...u,
                        rules: [
                            ...u.rules,
                            {
                                timeElapsedMaxRules: [],
                                timeslot: {
                                    isNight: true,
                                    price: baseRule?.timeslot?.price,
                                    every: baseRule?.timeslot?.every,
                                    maxRules: {
                                        type: TimeSlotMaxPriceType.None,
                                    },
                                }
                            }
                        ]
                    }
                }
                u = modifyNightHour(u, nightHours)
            }
            setRuleContext(u)
        },
        [ruleContext]
    );


    return (
        <InputGroup className="mb-3">
            <InputGroup.Text>夜間時間帯</InputGroup.Text>
            <Form.Select
                size="sm"
                value={value}
                onChange={onChange}
            >
                {choices.map((i, index) => (
                    <option key={index}>{i}</option>
                ))}
            </Form.Select>
        </InputGroup>
    );
}
