import React, {useContext} from "react";
import {TimeElapsedMaxPriceType, RuleContext, TimeSlotMaxPriceType, TRule, TRuleContext} from "../../context";
import {TargetDatePicker} from "./TargetDatePicker";
import {OperationBadge} from "../OperationBadge";
import {TimeUtils} from "../../utils";

export const RuleDescription = () => {
    const {ruleContext} = useContext(RuleContext);
    return (
        <div style={{
            position: "sticky",
            top: 0,
            zIndex: 50,
        }}>
            <div style={{
                paddingTop: "0.5rem"
            }}>
                <div style={{
                    backgroundColor: "white",
                    paddingLeft: "0.4rem",
                    paddingBottom: "0.2rem",
                    border: '2px solid lightgray',
                    marginBottom: "0.5rem"
                }}>
                    <TargetDatePicker/>
                    {formatRuleContext(ruleContext)}
                </div>
            </div>
        </div>
    );
};

function formatRuleContext(i: TRuleContext) {
    return i.rules.map(rule => (
            formatRule(rule)
        )
    )
}

const formatOperatingHour = (props: {
    rule: TRule
}) => {
    const {timeslot} = props.rule
    if (!timeslot.isNight) {
        return null
    }
    const {from, to} = timeslot.operatingHour!
    return (
        <>
            {`(${from.hour}:${String(from.min).padStart(2, "0")}〜${to.hour}:${String(to.min).padStart(2, "0")})`}
        </>
    )

}

function formatRule(rule: TRule) {
    const {timeslot, timeElapsedMaxRules} = rule
    let texts = [
        `￥${timeslot.price ?? "x"}/${TimeUtils.MSecToMin(timeslot.every) ?? "x"}m`,
    ]
    if (timeslot.maxRules.type !== TimeSlotMaxPriceType.None) {
        texts.push(`${timeslot.maxRules.type} ￥${timeslot.maxRules.price ?? "x"}円`,)
    }
    for (const j of timeElapsedMaxRules.filter(i => i.type !== TimeElapsedMaxPriceType.Once)) {
        texts.push(`${j.type} ￥${j.price ?? "x"}/${TimeUtils.MSecToHour(j.every) ?? "x"}h`)
    }
    const once = timeElapsedMaxRules.filter(i => i.type == TimeElapsedMaxPriceType.Once)
    if (once.length > 0) {
        const prices = once
            .map(j => `￥${j.price ?? "x"}/${TimeUtils.MSecToHour(j.every) ?? "x"}h`)
            .join(" ")
        texts.push(`${once[0].type} ${prices}`)
    }
    return (
        <div>
            {[
                <OperationBadge rule={rule}/>,
                formatOperatingHour({rule}),
                <span style={{
                    paddingLeft: "0.3rem",
                }}>
                    {texts.filter(i => i)
                        .join(" ")
                    }
                </span>,
            ].filter(i => i)}
        </div>
    )
}


