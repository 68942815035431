import {TOperatingHour} from "../core/types";

export const OperatingHour = (props: {
    operatingHour?: TOperatingHour,
}) => {
    const {operatingHour: i} = props

    if(i===undefined){
        return null
    }

    return (
        <div style={{
            paddingLeft:"0.3rem",
            paddingTop:"0.15rem",
        }}>
            {`${i.from.hour}:${String(i.from.min).padStart(2, "0")}〜${i.to.hour}:${String(i.to.min).padStart(2, "0")}`}
        </div>
    )
};