import {TimeSlotMaxPriceType, TRuleContext} from "./types";
import {TimeUtils} from "../utils";

export function defaultRuleContext(): TRuleContext {
    return {
        rules: [
            {
                timeslot: {
                    price: 300,
                    every: TimeUtils.MSecFromMin(15),
                    maxRules: {
                        type: TimeSlotMaxPriceType.None,
                    }
                },
                timeElapsedMaxRules: []
            },
        ]
    }
}