import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ContextsProvider, RuleContextProvider} from "./context";
import {Calculator} from "./components/Calculator";
import {BrowserRouter} from "react-router-dom";
import {PageUpdater} from "./components/PageUpdater";

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ContextsProvider>
                <App/>
                <PageUpdater/>
                <Calculator/>
            </ContextsProvider>
        </BrowserRouter>
    </React.StrictMode>
);

