import React, {ChangeEvent, useCallback, useContext} from "react";
import {RuleContext, TargetDateContext} from "../../context";
import {ButtonGroup, CloseButton, Form, InputGroup, ToggleButton} from "react-bootstrap";
import dayjs from "dayjs";
import {ModalResultContext} from "../../context/ModalResultContext";

export const TargetDatePicker = () => {
    const {targetDateContext, setTargetDateContext} = useContext(TargetDateContext);
    const {setModalResultContext} = useContext(ModalResultContext);

    const {startDate, endDate, simplified} = targetDateContext
    const targetDate = dayjs(startDate).tz("Asia/Tokyo")
    const duraton = endDate.getTime() - startDate.getTime()

    const close = useCallback(() => {
        setModalResultContext({
            visible: false
        })
    }, [])

    const onDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value
        const [y, m, d] = text.split("-")
        let updated = targetDate
            .set("years", parseInt(y))
            .set("months", parseInt(m) - 1)
            .set("date", parseInt(d))
        setTargetDateContext({
            ...targetDateContext,
            startDate: updated.toDate(),
            endDate: new Date(updated.toDate().getTime() + duraton),
        })
    }, [targetDateContext, setTargetDateContext])

    const onTimeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value
        const [h, m] = text.split(":")
        let updated = targetDate
            .set("hours", parseInt(h))
            .set("minutes", parseInt(m))
        setTargetDateContext({
            ...targetDateContext,
            startDate: updated.toDate(),
            endDate: new Date(updated.toDate().getTime() + duraton),
        })

    }, [targetDateContext])

    const onToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setTargetDateContext({
            ...targetDateContext,
            simplified: !simplified
        })
    }, [targetDateContext])

    return (
        <>
            <div style={{
                paddingTop: "0.4rem",
                height: "3rem",
            }}>
                <InputGroup className="mb-3">
                    <Form.Control
                        type={"date"}
                        value={targetDate.format("YYYY-MM-DD")}
                        onChange={onDateChange}
                    />
                    <Form.Control
                        type={"time"}
                        value={targetDate.format("HH:mm")}
                        onChange={onTimeChange}
                    />
                    <ToggleButton
                        type="checkbox"
                        id="toggle-check"
                        value={simplified ? "1" : "0"}
                        variant="outline-primary"
                        checked={simplified}
                        onChange={onToggle}
                        active={simplified}
                    >
                        {simplified ? "簡易" : "詳細"}
                    </ToggleButton>
                    <CloseButton onClick={close}/>
                </InputGroup>
            </div>
        </>
    );
};
